<template>
	<div class="ele-body">
		<div style="height: 166px;" v-if="earningsRecord.is_view !== 0">
			<el-row :gutter="20">
				<el-col :span="8">
					<el-card shadow="never" class="grid-content bg-purple">
						<div class="d-flex smsInner">
							<div class="blueLine">|</div>
							<div>本月订单服务费提点收益（元）</div>
						</div>
						<div style="margin: 32px 0 0 20px;font-size: 34px;"><img src="./money.png" style="margin-right: 10px;"/>{{earningsRecord.month}}</div>
					</el-card>
				</el-col>
				<el-col :span="8">
					<el-card shadow="never" class="grid-content bg-purple">
						<div class="d-flex smsInner">
							<div class="blueLine">|</div>
							<div>本周订单服务费提点收益（元）</div>
						</div>
						<div style="margin: 32px 0 0 20px;font-size: 34px;"><img src="./money.png" style="margin-right: 10px;"/>{{earningsRecord.week}}</div>
					</el-card>
				</el-col>
				<el-col :span="8">
					<el-card shadow="never" class="grid-content bg-purple">
						<div class="d-flex smsInner">
							<div class="blueLine">|</div>
							<div>今日订单服务费提点收益（元）</div>
						</div>
						<div style="margin: 32px 0 0 20px;font-size: 34px;"><img src="./money.png" style="margin-right: 10px;"/>{{earningsRecord.day}}</div>
					</el-card>
				</el-col>
			</el-row>
		</div>
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>

				<el-form-item label="订单状态:" label-width="80px">
					<el-select v-model="table.where.status" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="100" />
						<el-option label="等待接单" value="1" />
						<el-option label="待支付" value="9" />
						<el-option label="已完成" value="10" />
						<el-option label="已取消" value="2" />
						<el-option label="进行中" value="3" />
						<el-option label="手动结束" value="99" />
					</el-select>
				</el-form-item>
				<el-form-item label="订单类型:" label-width="80px">
					<el-select v-model="table.where.errand_type" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="10" />
						<el-option label="代买" value="1" />
						<el-option label="取送件" value="2" />
					</el-select>
				</el-form-item>
				<el-form-item label="跑腿姓名:" label-width="80px">
					<el-input v-model="table.where.driver_name" placeholder="请输入商家姓名" clearable />
				</el-form-item>
				<el-form-item label="跑腿手机号:" label-width="90px">
					<el-input v-model="table.where.driver_phone" placeholder="请输入商家手机号" clearable />
				</el-form-item>
				<el-form-item label="用户姓名:" label-width="80px">
					<el-input v-model="table.where.user_name" placeholder="请输入用户昵称" clearable />
				</el-form-item>
				<el-form-item label="用户手机号:" label-width="90px">
					<el-input v-model="table.where.user_phone" placeholder="请输入用户手机号" clearable />
				</el-form-item>
				<el-form-item label="省:" label-width="35px" class="w-170">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" label-width="35px" class="w-150">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)" placeholder='请选择市' clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" label-width="62px" class="w-200">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单号:" label-width="70px">
					<el-input v-model="table.where.order_id" placeholder="请输入订单编号" clearable />
				</el-form-item>
				<el-form-item label="下单时间：" label-width="90px">
					<el-date-picker v-model="table.where.create_time" type="date" placeholder="选择日期" style="margin-right: 10px"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">
						查询</el-button>
					<el-button @click="(table.where={	type: 4})&&$refs.table.reload()">重置</el-button>
					<download v-if="permission.includes('sys:shop_list:export')" style="margin-left: 0"
						class="ele-btn-icon ml-20" label="导出" url="order/exportExcelShop"
						:params="exportOrderParams()" />
				</el-form-item>


			</el-form>

			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="order_id" label="订单号" show-overflow-tooltip min-width="200" />
					<el-table-column prop="type_name" label="订单类型" show-overflow-tooltip min-width="110" />

					<el-table-column label="发单时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="user_name" label="用户昵称" show-overflow-tooltip min-width="140" />
					<el-table-column prop="user_phone" label="用户手机号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="driver_name" label="跑腿姓名" show-overflow-tooltip min-width="120" />
					<el-table-column prop="driver_phone" label="跑腿手机号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="driver_company" label="所属公司" show-overflow-tooltip min-width="120" />
					<el-table-column prop="order_price" label="订单金额(元)" show-overflow-tooltip min-width="120" />
					<el-table-column prop="driver_get_money" label="实际到账(元)" show-overflow-tooltip min-width="120" />
					<el-table-column prop="handling_fee" label="手续费(元)" show-overflow-tooltip min-width="120" />
					<el-table-column prop="status_name" label="订单状态" show-overflow-tooltip min-width="120" />
					<el-table-column prop="time_out_status" label="是否超时" show-overflow-tooltip min-width="120" />
					<el-table-column prop="time_out_fee" label="超时扣款(元)" show-overflow-tooltip min-width="120" />
					<el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">

						<template slot-scope="{row}">
							<el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false"	v-if="permission.includes('sys:shop_list:view')">查看</el-link>
              <el-link icon="el-icon-view" type="primary" :underline="false">跑腿调度</el-link>
							<el-link @click="order_status_update(row)" icon="el-icon-s-claim" type="primary"
								:underline="false" v-if="permission.includes('sys:shop_list:order_finish') && row.status !== 2 && row.status !== 10">手动结束</el-link>

							<el-dropdown @command="command => dropClick(command,row)">
								<span class="el-dropdown-link" style="margin-left: 10px;">

									<el-link type="primary" icon="el-icon-more" :underline="false"></el-link>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-delete" command="remove"
										v-if="permission.includes('sys:shop_list:delete')">删除</el-dropdown-item>
									<el-dropdown-item icon="el-icon-notebook-2" command="handleChat"
										v-if="permission.includes('sys:shop_list:online_log')">聊天记录</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>


					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 查看订单详情 -->
		<el-dialog v-dialogDrag title="查看订单详情" :visible.sync="dialogTableVisibleCheck" width="1000px" :destroy-on-close="true" :lock-scroll="false">
			<el-form :model="checkForm" label-width="130px">
				<div style="display: flex;">
					<div style="padding: 0 20px 0 40px;border-right: 1px solid #C6C6C6;">
						<el-form-item label="发单时间：" prop="money">
							{{checkForm.create_time*1000 | toDateString}}
						</el-form-item>
						<el-form-item label="订单号:" prop="invite_money">
							{{checkForm.order_id}}
						</el-form-item>
						<el-form-item label="订单类型：" prop="total_time">
							{{checkForm.type_name}}
						</el-form-item>
						<el-form-item label="发单起点所在地：" prop="money">
							{{checkForm.place_address}}
						</el-form-item>
						<el-form-item label="发单终点所在地：" prop="invite_money">
							{{checkForm.target_address}}
						</el-form-item>
						<el-form-item label="用户语音：" prop="audio_length">
							{{checkForm.audio_length}}
						</el-form-item>
						<el-form-item label="发单用户姓名：" prop="place_name" class="w-120">
							{{checkForm.place_name}}
						</el-form-item>
						<el-form-item label="发单用户手机号：" prop="place_phone">
							{{checkForm.place_phone}}
						</el-form-item>
						<el-form-item label="起点：" prop="place_address">
							{{checkForm.place_address}}
						</el-form-item>
						<el-form-item label="终点：" prop="target_address">
							{{checkForm.target_address}}
						</el-form-item>
						<el-form-item label="接单司机：" prop="phone">
							{{checkForm.driver_name}}
						</el-form-item>
					</div>
					<div>
						<el-form-item label="司机手机号：">
							{{checkForm.driver_phone}}
						</el-form-item>
						<el-form-item label="小费：" prop="order_price">
							{{checkForm.order_price}}
						</el-form-item>
						<el-form-item label="优惠券减免：" prop="discount_money">
							{{checkForm.discount_money}}
						</el-form-item>
						<el-form-item label="零钱抵扣：" prop="bonus">
							{{checkForm.bonus}}
						</el-form-item>
						<el-form-item label="订单金额(元)：" prop="order_price">
							{{checkForm.order_price}}
						</el-form-item>
						<el-form-item label="客户应付金额(元)：" prop="price">
							{{checkForm.price}}
						</el-form-item>
						<el-form-item label="抢单时间：" prop="receive_time">
							{{checkForm.receive_time}}
						</el-form-item>
						<el-form-item label="完成时间：" prop="complete_time">
							{{checkForm.complete_time}}
						</el-form-item>
						<el-form-item label="支付方式：" prop="payment">
							{{checkForm.payment}}
						</el-form-item>
						<el-form-item label="订单状态：" prop="status_name">
							{{checkForm.status_name}}
						</el-form-item>
					</div>
				</div>
			</el-form>
		</el-dialog>
		<!-- 聊天记录 -->
		<el-dialog v-dialogDrag title="聊天记录" :visible.sync="dialogTableVisibleChatRecord" class="records" @close='closeDialog' width="600px">
			<div class="charRecord-box">
				<div class="driverBox" style="padding:20px;width: 500px;">
					<div class="" style="width:80px;height:80px;">
						<img style="width:60px;height:60px;border-radius:50%" :src="driverInfo.headimg" />
					</div>
					<div class="">
						<div class="" style="font-size:17px;margin:5px 0">{{driverInfo.name}}</div>
						<div class="" style="font-size:13px">{{driverInfo.car_number}}</div>
					</div>
				</div>
				<div>
					<div class="charRecord-inner" v-for="(option,index) in charList" :key="option.id">
						<!-- 时间 -->
						<div class="w-100" v-if="option.p_show==1 && option.type !== 33 && option.type !== 34 ">
							<div class="charRecord-time">{{option.create_time}}</div>
						</div>
						<!-- 系统消息-->
						<div class="w-100" v-if="(option.content.S==5 || option.content.S==100 || option.content.S==1) && option.p_show==1">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo.png">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title p-10">{{option.content.M}}</div>
							</div>
						</div>
						<!-- 左侧 完善目的地-->
						<div class="w-100" v-if="option.content.S==27 && option.direction==3">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo.png">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title ">
									<div class="">
										<div class="m-10 mb-0">您可以完善一下目的地</div>
										<div class="whiteAddress">{{orderInfo.place_address}}</div>
										<div class="addressBtn">
											<span class="ignore">忽略</span>
											<span class="submit">提交</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音-->
						<div class="w-100 " v-if="option.content.S==21 && option.direction==2">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title2 p-10 " :style="{width:option.bgWidth}"
									@click="handlePlay(option.content.P.URL,option.id,index)"
									v-if="option.isPlay==true ">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle'+index">您的浏览器不支持</audio>
									<div class="lvoide1">
										<img src="../../../assets/images/ic_b_yuyin@2x.png">
									</div>
									<div>{{option.content.P.LONG}}''</div>
								</div>
								<div class="charRecord-title2 p-10" :style="{width:option.bgWidth}"
									v-if="option.isPlay==false">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle'+index">您的浏览器不支持</audio>
									<div class="lvoide1">
										<img src="../../../assets/images/ic_b_yuyin.gif">
									</div>
									<div>{{option.content.P.LONG}}''</div>
								</div>
							</div>
						</div>
						<!-- 右侧 语音-->
						<div class="w-100 " v-if="option.content.S==21 && option.direction==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 bgGreen " :style="{width:option.bgWidth}"
									@click="handlePlay(option.content.P.URL,option.id,index)"
									v-if="option.isPlay==true">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle'+index">您的浏览器不支持</audio>
									<div style="color:#fff">{{option.content.P.LONG}}''</div>
									<div class="rvoide1">
										<img src="../../../assets/images/yuyin-ico.png" :ref="'changeImg'+index">
									</div>

								</div>
								<div class="charRecord-title2 p-10 bgGreen" :style="{width:option.bgWidth}"
									v-if="option.isPlay==false">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle'+index">您的浏览器不支持</audio>

									<div style="color:#fff">{{option.content.P.LONG}}''</div>
									<div class="rvoide1">
										<img src="../../../assets/images/yuyin-gif.gif">
									</div>

								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 图片-->
						<div class="w-100" v-if="option.content.S==22">
							<div class="charRecord-rightItem">
								<div class="charRecord-title1 p-10" @click="hanlePicture(option.content.P.URL)">
									<img class="" :src="option.content.P.URL">
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img width='100px' height='100px' class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 视频-->
						<div class="w-100" v-if="option.content.S==6">
							<div class="charRecord-rightItem">
								<div class="charRecord-title1 p-10">
									<video width="100" height="100" controls>
										<source :src="option.content.P.URL" type="video/mp4">
									</video>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">

									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 地图-->
						<div class="w-100" v-if="option.content.S==26 && option.direction==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-titleMap p-10">
									<div class="amap-page-container">
										<div :style="{width:'100%',height:'180px'}">
											<!-- vid="amapDemo1" -->
											<el-amap ref="map" :zoom="zoom" :center="option.content.P.POINT">
												<el-amap-marker></el-amap-marker>
											</el-amap>
										</div>
									</div>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 左侧 地图-->
						<div class="w-100" v-if="option.content.S==26 && option.direction==2">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-titleMap p-10">
									<div class="amap-page-container">
										<div :style="{width:'100%',height:'180px'}">
											<el-amap ref="map" :zoom="zoom" :center="option.content.P.POINT">
												<el-amap-marker></el-amap-marker>
											</el-amap>
										</div>
									</div>
								</div>


							</div>
						</div>

						<!-- 左侧 语音通话 对方已取消-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==3 && option.p_show==1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3grey@2x.png">
									</div>
									<div>{{option.content.P.voiceCallMessage}}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 已拒接-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==4 && option.p_show==1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3grey@2x.png">
									</div>
									<div>{{option.content.P.message}}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 未接听-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==5 && option.p_show==1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3grey@2x.png">
									</div>
									<div>{{option.content.P.voiceCallMessage}}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 通话时长-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==2 && option.content.P.call_status==6 && option.p_show==1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phonegrey@2x.png">
									</div>
									<div>{{option.content.P.message}}</div>
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 已取消-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==3 && option.p_show==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3@2x.png">
									</div>
									<div>{{option.content.P.message}}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 对方已拒接-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==4 && option.p_show==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3@2x.png">
									</div>
									<div>{{option.content.P.voiceCallMessage}}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 对方无应答-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==5 && option.p_show==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3@2x.png">
									</div>
									<div>{{option.content.P.voiceCallMessage}}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 通话时长-->
						<div class="w-100"
							v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==6 && option.p_show==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone@2x.png">
									</div>
									<div>{{option.content.P.message}}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 左侧 订单账单-->
						<div class="w-100" v-if="option.content.S==31">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo.png" />
								</div>
								<div class="hh"></div>
								<div class="charRecord-Paytitle">
									<div class="w-30">
										<div class="m-10 mb-0 text-center">订单账单</div>
										<div class="m-10 mb-0 text-center">
											<span class="price">{{option.show_data.price}}</span>元
										</div>
										<div class="p-10 text-center payBox">
											<div class="payTitle">行程车费</div>
											<div class="payPrice">{{option.show_data.price}}元</div>
										</div>
										<div class="p-10  payBox">
											<div class="payTitle">零钱抵现</div>
											<div class="payPrice"><span
													style="color: #FF9D00;">-{{option.show_data.bonus}}</span>元</div>
										</div>
										<div class="p-10 payType">
											<div>
												<img src="../../../assets/images/share_ic_wechat@2x.png"
													style="float: left;" alt="" width="30px" height="30px;">
												<span class="wxPay">微信支付</span>
											</div>

											<img src="../../../assets/images/ic_arrow@2x.png"
												style="float: right;margin-top: 3px;" alt="" width="10px"
												height="20px;">

										</div>
										<div class="p-10 payType borderTop">
											<div>
												<img src="../../../assets/images/ic_chat_pt_pay_alipay.png"
													style="float: left;" alt="" width="30px" height="30px;">
												<span class="wxPay">支付宝支付</span>
											</div>

											<img src="../../../assets/images/ic_arrow@2x.png"
												style="float: right;margin-top: 3px;" alt="" width="10px"
												height="20px;">
										</div>


									</div>
								</div>

							</div>
						</div>
						<!-- 右侧 支付成功-->
						<div class="w-100" v-if="option.content.S==32 && option.p_show==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-Paytitle ">
									<div>
										<div class="paySuccess">支付成功</div>
										<div class="money">
											<span
												style="color:#84B625;font-size:25px;text-align:center">{{option.content.PRICE}}</span>元
										</div>
										<div class="otherPay">红包另支付0元</div>
									</div>

								</div>
								<div class="rhh"></div>
								<div class="charRecordRightImage">
									<img class="charHeadimg" :src="option.user.headimg" />
								</div>

							</div>
						</div>

						<!-- 左侧 被动红包 -->
						<div class="w-100" v-if="option.content.S==72">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-Paytitle">
									<div class="">
										<div class="text-align pbt-10">对话红包</div>
										<div class="bill-box">
											<div class="">红包金额</div>
											<div class=""><span>{{option.content.P.PRICE}}</span>元</div>
										</div>
										<div class="btn">
											<div class="btn-ignore">驳回</div>
											<div class="line"></div>
											<div class="btn-ignore">发红包</div>
										</div>

									</div>
								</div>
							</div>
						</div>
						<!-- 右侧 被动红包-->
						<div class="w-100" v-if="option.content.S==69">
							<div class="charRecord-rightItem">
								<div class="charRecord-Paytitle">
									<div class="">
										<div class="text-align pbt-10">对话红包</div>
										<div class="bill-box">
											<div class="">红包金额</div>
											<div class=""><span>0.01</span>元</div>
										</div>
										<div class="btn">
											<div class="btn-ignore text-align">已驳回</div>
										</div>
									</div>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 未领取主动红包-->
						<div class="w-100" v-if="option.content.S==71 && option.content.H==1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title3">
									<img src="../../../assets/images/bg_hb1@2x.png"
										style="position:relative;width:100%;height:100%" />

									<div class="red-box" style="position:absolute;top:30px">
										<div>
											<img class="package" src="../../../assets/images/img_hb1@2x.png" />
										</div>
										<div class="">
											<div class="">主动红包，司机可领取</div>
											<div class="font--9">未领取</div>
										</div>
									</div>
									<div class="address-box" style="position:absolute;top:64px">
										<div class="">
											<img class="weizhi" src="../../../assets/images/hb_logo@2x.png" />
										</div>
										<div class="font--9">咱县打车</div>
									</div>
								</div>

								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 已领取主动红包-->
						<div class="w-100" v-if="option.content.S==71 && option.content.H==2">
							<div class="charRecord-rightItem">
								<div class="charRecord-title3">
									<img src="../../../assets/images/bg_hb2@2x.png"
										style="position:relative;width:100%;height:100%" />

									<div class="red-box" style="position:absolute;top:30px">
										<div>
											<img class="package" src="../../../assets/images/img_hb2@2x.png" />
										</div>
										<div class="">
											<div class="">主动红包，司机可领取</div>
											<div class="font--9">未领取</div>
										</div>
									</div>
									<div class="address-box" style="position:absolute;top:64px">
										<div class="">
											<img class="weizhi" src="../../../assets/images/hb_logo@2x.png" />
										</div>
										<div class="font--9">咱县打车</div>
									</div>
								</div>

								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ampBox" :style="{display:ampDisplay==true?'block':'none'}" @click="handleClose">
				<img :src="ampSrc">
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import download from '@/components/Download/index'
	// import uploadImage from '@/components/uploadImage';
	export default {
		name: "SysUser",
		data() {
			return {
				table: {
					url: '/order/shop_list',
					where: {
						type: 4
					}
				}, // 表格配置
				choose: [], // 表格选中数据
				editForm: {}, // 表单数据
				checkForm: {},
				editRules: { // 表单验证规则
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					},{
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, 
						message: '请输入正确的手机号', 
						trigger: 'blur',
					}],
					name: [{
						required: true,
						message: '请输入昵称',
						trigger: 'blur'
					}],
					sex: [{
						required: true,
						message: '请输入性别，1男2女',
						trigger: 'blur'
					}],
					home: [{
						required: true,
						message: '请输入家乡名称，对应aid',
						trigger: 'blur'
					}],

					money: [{
						required: true,
						message: '请输入余额',
						trigger: 'blur'
					}],

					losemoney: [{
						required: true,
						message: '请输入已提现/使用余额',
						trigger: 'blur'
					}],

				},
				dialogTableVisibleCheck: false,
				dialogTableVisibleEdit: false,
				dialogTableVisibleUrgent: false,
				dialogTableVisibleChatRecord: false,
				provArr: [],
				cityArr: [],
				districtArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				charList: [],
				audioArr: [],
				zoom: 12,
				center: [104.07, 30.67],
				ampSrc: '',
				driverInfo: {},
				orderInfo: {},
				audio: '',
				ampDisplay: false,
				earningsRecord: {},
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				var data = JSON.parse(res.data)
				this.provArr = data
			})
			this.$http.post('/driver/earnings_record', {
				type: 3
			}).then(res => {
				this.earningsRecord = res.data.data
			})
			var url = this.$route.query.orderId

			if (url != undefined && url != '') {
				this.dialogTableVisibleChatRecord = true
				this.getChat(url)
			} else {
				this.dialogTableVisibleChatRecord = false
			}

		},
		computed: {
			...mapGetters(["permission"]),
		},
		components: {
			// uploadImage,
			download
		},
		mounted() {},
		methods: {
			// 导出数据参数
			exportOrderParams() {
				const query = this.table.where
				// const params = this.params
				const selectionIds = this.choose.map(item => {
					return item.id
				})

				return {
					ids: selectionIds,
					...query
				}
			},
			dropClick(command, row) {
				if (command === 'handlecheck') { // 查看用户信息
					this.handlecheck()
				} else if (command === 'remove') { //删除
					this.remove()
				} else if (command === 'handleUrgent') { //紧急联系人
					this.handleUrgent(row)
				} else if (command === 'handleEdit') { //修改用户信息
					this.handleEdit(row)
				} else if (command === 'handleChat') { //聊天记录
					this.handleChat(row)
				}
			},
			hanlePicture(src) {
				this.ampDisplay = true
				this.ampSrc = src
			},
			handleClose() {
				this.ampDisplay = false
			},
			closeDialog() {
				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (let i = 0; i < middlePlay.length; i++) {
					middlePlay[i].pause()
					middlePlay[i].currentTime = 0
				}
			},
			handlePlay(url, id, index) {
				let that = this
				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (let i = 0; i < middlePlay.length; i++) {
					middlePlay[i].pause()
					middlePlay[i].currentTime = 0
				}
				var middle = 'middle' + index
				middlePlay = this.$refs[middle][0]
				middlePlay.play()
				for (let i = 0; i < this.charList.length; i++) {
					if (i == index) {
						this.charList[index].isPlay = false
						middlePlay.addEventListener('ended', function() {
							that.charList[index].isPlay = true
						})
					} else {
						that.charList[i].isPlay = true
					}
				}
			},
			// 聊天记录
			getChat(id) {
				const loading = this.$loading({
					lock: true,
					target: document.querySelector(".records")
				});
				this.$http.post('/order/chat_log', {
					id: id
				}).then(res => {
					var data = res.data
					loading.close()
					data.log.forEach(function(item) {
						item.isTimeShow = 1
						if (item.content.S == 21) {
							item.isPlay = true
							if (item.content.P.LONG == 1) {
								item.bgWidth = '8rem'
							}
							item.bgWidth = 8 + 0.05 * (parseInt(item.content.P.LONG) - 1) + 'rem'
						}
						if (item.content.S == 26) {
							item.content.P.POINT = item.content.P.POINT.split(',')
							//console.log(item.content.P.POINT)

						}
					});
					for (var i = 0; i < data.log.length; i++) {
						for (var j = i + 1; j < data.log.length - 1; j++) {
							if (data.log[i].create_time == data.log[j].create_time) {
								data.log[j].isTimeShow = 0 //不显示时间
							} else {
								data.log[j].isTimeShow = 1
							}
						}
					}
					this.charList = data.log
					this.driverInfo = data.driver
					this.orderInfo = data.order_info
				})
			},
			handleChat(row) {
				this.dialogTableVisibleChatRecord = true
				this.getChat(row.id)
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				//console.log(e)
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				var cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			/**
			 *修改省
			 **/
			handleChangeProv1(e) {
				//console.log(e)
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr.forEach(function(item) {
					if (item.name == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cname = ''
					this.editForm.aname = ''
				})
			},
			/**
			 *修改市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				var cid = ''
				this.cityArr.forEach(function(item) {
					if (item.name == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''
				})
				}else{
					this.editForm.pname = ''
					this.editForm.aname = ''
				}
			},
			/**
			 /**
			 *查看用户信息
			 **/
			handlecheck(row) {
				this.dialogTableVisibleCheck = true
				this.checkForm = row;
			},

			/**
			 * 刪除(批量刪除)
			 */
			remove(row) {
				//console.log(row)
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					let ids = this.choose.map(d => d.id);
					this.$confirm('确定要删除选中的订单吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/order/delete', {
							id: ids
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/order/delete', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}
			},
			/**
			 * 拉入黑名单
			 */
			order_status_update(row) {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/order/order_status_update', {
					id: row.id
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			},
			exportExcel() {
				let info = JSON.parse(JSON.stringify(this.table.where));
				//console.log('order data export');
				this.$http
					.get("/order/exportExcel", info)
					.then((res) => {
						let data = res.data;
						if (data.code == 0) {
							// 下载文件
							window.location.href = data.data;
							this.$message({
								type: "success",
								message: "导出成功",
							});
						} else {
							this.$message.error("导出失败");
						}
					})
			},

		}
	}
</script>

<style scoped>
	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.changeStyle {
		width: 150px;
		margin-right: 30px;
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}

	.el-form-item__label {
		width: 20%;
		text-align: left
	}

	.el-date-editor /deep/.el-input,
	.el-date-editor /deep/.el-input__inner {
		width: 100% !important;
	}

	/deep/.el-dialog {
		margin-top: 30px !important;
		width: 50%;
		height: 76%;
	}

	.el-col {
		border-radius: 4px;
	}

	.bg-purple-dark {
		background: #99a9bf;
	}

	.bg-purple {
		background: #fff;
		height: 160px;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}
	.smsInner{
		border-bottom: 1px solid #ddd;
		padding-bottom: 14px;
	}
</style>
